import React, { useState, useCallback, useMemo, forwardRef, useImperativeHandle } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerDetails } from "../../store/cart/cart.action";
import { selectCustomerDetails } from "../../store/cart/cart.selector";
import FormInput from "../form-input/form-input.component";


const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const OrderCustomerDetailsBottomSheet = forwardRef(({ onSubmit }: { onSubmit: () => void }, ref) => {
  const dispatch = useDispatch();
  const customerDetails = useSelector(selectCustomerDetails);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const defaultFormFields = useMemo(
    () => customerDetails || { customerEmail: "", customerName: "", customerMobile: "" },
    [customerDetails]
  );

  const [formFields, setFormFields] = useState(defaultFormFields);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateField = useCallback((name: string, value: string): string => {
    if (!value) {
      return `Please enter ${name.replace("customer", "").toLowerCase()} to continue.`;
    }

    switch (name) {
      case "customerName":
        return value.length < 3 ? "Name must be at least 3 characters long." : "";
      case "customerEmail":
        return emailRegex.test(value.toLowerCase()) ? "" : "Invalid email address.";
      case "customerMobile":
        return value.length < 4 || isNaN(parseInt(value)) ? "Invalid mobile number." : "";
      default:
        return "";
    }
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormFields((prev) => ({ ...prev, [name]: value }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validateField(name, value),
      }));
    },
    [validateField]
  );

  const handleSubmit = () => {
    const newErrors = Object.fromEntries(
      Object.entries(formFields)
        .map(([name, value]) => [name, validateField(name, value)])
        .filter(([, error]) => error)
    );

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return false;
    }
    dispatch(setCustomerDetails(formFields));
    return true;
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <>
      <FormInput
        label="Name"
        type="text"
        required
        name="customerName"
        value={formFields.customerName}
        onChange={handleChange}
        error={errors.customerName}
        placeholder="Enter your name"
        style={{ marginBottom: isMobile ? 24 : 32 }}
      />

      <FormInput
        label="Email"
        type="email"
        required
        name="customerEmail"
        value={formFields.customerEmail}
        onChange={handleChange}
        error={errors.customerEmail}
        placeholder="Enter your email address"
        style={{ marginBottom: isMobile ? 24 : 32 }}
      />

      <FormInput
        label="Mobile"
        type="text"
        required
        name="customerMobile"
        value={formFields.customerMobile}
        onChange={handleChange}
        error={errors.customerMobile}
        placeholder="Enter mobile number"
      />
    </>
  );
});

export default OrderCustomerDetailsBottomSheet;