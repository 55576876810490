export const firebaseConfig = {
  apiKey: "AIzaSyB0ConljEezP6KxXyMeVdqMrGFmG0XTcvY",
  authDomain: "swifti-prod.firebaseapp.com",
  projectId: "swifti-prod",
  storageBucket: "swifti-prod.appspot.com",
  messagingSenderId: "1047382339182",
  appId: "1:1047382339182:web:8058c40a2cbca14af8007f",
  measurementId: "G-CH6EMQWXJQ"
};

export const googleMerchantId = "BCR2DN4T265LPHRA"
export const tyroLiveMode = true
