import { useSelector } from "react-redux";
import CheckoutItem from "../../components/checkout-item/checkout-item.component";
import {
  selectCartItems,
  selectCartTotal,
  selectOrderType,
  selectScheduledTime,
  selectTableNumber,
} from "../../store/cart/cart.selector";
import {
  selectKitchen,
  selectOnlineOrdersConfigs,
} from "../../store/kitchen/kitchen.selector";
import "./checkout.styles.jsx";
import {
  BottomContainer,
  CheckoutContainer,
  EmptyCartSubtitleText,
  EmptyCartText,
  DetailsTitle,
  PickupDetailsContainer,
  Title,
  TotalTextSm,
  TotalTextLg,
  TotalContainer,
  PickupDetailsItem,
  TextLarge,
  TextSmall,
  TotalItem,
} from "./checkout.styles.jsx";
import { BUTTON_TYPE_CLASSES } from "../../components/button/button.component";
import PaymentButton from "../../components/payment-button/payment-button.component";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CheckoutDetails from "../../components/checkout-details/checkout-details.component";
import EmptyCartImage from "../../assets/empty-cart.png";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../utils/firebase/firebase.utils";
import { Box, useMediaQuery } from "@mui/material";
import OrderTypeBottomSheet from "../../components/order-type-bottom-sheet/order-type-bottom-sheet.component";
import { getColor, getFontName } from "../../utils/utils.common";
import OrderNoteBottomSheet from "../../components/order-note-bottom-sheet/order-note-bottom-sheet.component";
import OrderCustomerDetailsBottomSheet from "../../components/order-customer-details-bottom-sheet/order-customer-details-bottom-sheet.component";
import { roundedUp } from "../../utils/roundUpNumber";
import ButtonAction from "../../components/shared/buttonAction/buttonAction.component";

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const kitchen = useSelector(selectKitchen);
  const cartItems = useSelector(selectCartItems);
  const cartSubtotal = useSelector(selectCartTotal);

  const searchParams = new URLSearchParams(window.location.search);
  const tableNumber = searchParams.get("tableNumber");

  const navigate = useNavigate();

  //   const getTableNumber = useSelector(selectTableNumber);
  const getScheduledTime = useSelector(selectScheduledTime);
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
  const getOrderType =
    tableNumber && onlineOrdersConfig?.dineInEnabled ? "dinein" : "takeaway";

  const cardFeePercent = onlineOrdersConfig?.cardFeePercent
    ? onlineOrdersConfig?.cardFeePercent / 100
    : 0;
  const cardFeeFixedCharge = onlineOrdersConfig?.cardFeeFixedCharge
    ? onlineOrdersConfig?.cardFeeFixedCharge / 100
    : 0;

  const cardTotalFee = roundedUp(
    cartSubtotal * cardFeePercent + cardFeeFixedCharge
  );
  const cartTotal = roundedUp(Number(cartSubtotal) + Number(cardTotalFee));

  const [showOrderTypeBottomSheet, setShowOrderTypeBottomSheet] =
    useState(false);
  const [orderTypeError, setOrderTypeError] = useState(false);
  const PickupDetailsRef = useRef(null);

  const { kitchenSlug } = useParams();

  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    logEvent(analytics, "checkout_visited", {
      kitchenName: kitchen?.kitchenName,
    });
  }, [kitchen]);

  const headerModalBackground = getColor(
    "header-modal-background",
    onlineOrdersConfig
  );
  const brandAccent = getColor("brand-accent", onlineOrdersConfig);
  const brandFontStyle = getFontName("brand-font-style", onlineOrdersConfig);
  const backgroundTertiary = getColor(
    "background-tertiary",
    onlineOrdersConfig
  );
  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);

  const childRef = useRef(null);

  const triggerSubmit = async () => {
    if (childRef.current) {
      const isValid = await childRef.current.handleSubmit();
      if (isValid) {
        const currentParams = new URLSearchParams(window.location.search);

        navigate(
          `/${kitchenSlug}/checkout/payment?${currentParams.toString()}`
        );
      }
    }
  };

  if (cartItems.length === 0) {
    return (
      <Box
        sx={{
          backgroundColor: headerModalBackground,
          minHeight: "calc(100vh - 72px)",
          "@media screen and (max-width: 768px)": {
            minHeight: "calc(100vh - 64px)",
          },
        }}
      >
        <CheckoutContainer
          style={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            paddingTop: "72px",
            paddingBottom: "72px",
          }}
        >
          <img
            src={EmptyCartImage}
            style={{ width: "150px", paddingBottom: "24px" }}
            alt="Empty Cart"
          />
          <EmptyCartText>Your cart is empty</EmptyCartText>
          <EmptyCartSubtitleText>
            Go back to the menu to add some items.
          </EmptyCartSubtitleText>
        </CheckoutContainer>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          backgroundColor: headerModalBackground,
          minHeight: "calc(100vh - 72px)",
          "@media screen and (max-width: 768px)": {
            minHeight: "calc(100vh - 64px)",
          },
        }}
      >
        <CheckoutContainer>
          {!isMobile && (
            <Title brandFontStyle={brandFontStyle} brandAccent={brandAccent}>
              Your Cart
            </Title>
          )}
          <PickupDetailsContainer backgroundTertiary={backgroundTertiary}>
            {kitchen.fullAddress && (
              <PickupDetailsItem ref={PickupDetailsRef}>
                {getOrderType === "takeaway" && (
                  <CheckoutDetails
                    onClick={() =>
                      setShowOrderTypeBottomSheet(!showOrderTypeBottomSheet)
                    }
                    title="Take Away"
                    body={`${
                      getScheduledTime === "ASAP"
                        ? `Pick up ASAP (Ready in ${onlineOrdersConfig?.orderReadyTime} Mins)`
                        : `Pick Up ${getScheduledTime}`
                    }`}
                  />
                )}
                {getOrderType === "dinein" && (
                  <CheckoutDetails
                    onClick={() =>
                      setShowOrderTypeBottomSheet(!showOrderTypeBottomSheet)
                    }
                    title="Dine In"
                    body={`${
                      tableNumber !== null
                        ? `Table ${tableNumber}`
                        : "Enter Table Number"
                    }`}
                    error={orderTypeError ? "Enter Table Number" : ""}
                  />
                )}
                <ButtonAction
                  onClick={() =>
                    setShowOrderTypeBottomSheet(!showOrderTypeBottomSheet)
                  }
                  size="medium"
                  label="Change"
                  backgroundTertiary={backgroundTertiary}
                  brandPrimary={brandPrimary}
                />
              </PickupDetailsItem>
            )}
            <PickupDetailsItem>
              <div>
                <TextLarge>{kitchen.kitchenName}</TextLarge>
                <TextSmall>{kitchen.fullAddress}</TextSmall>
              </div>
            </PickupDetailsItem>
          </PickupDetailsContainer>
          {cartItems.map(cartItem => (
            <CheckoutItem key={cartItem.cartItemId} cartItem={cartItem} />
          ))}
          <TotalContainer>
            <TotalItem>
              <TotalTextSm>Subtotal</TotalTextSm>
              <TotalTextSm>${cartSubtotal.toFixed(2)}</TotalTextSm>
            </TotalItem>
            {cardTotalFee !== 0 && (
              <TotalItem>
                <TotalTextSm>Card Surcharge</TotalTextSm>
                <TotalTextSm>${Number(cardTotalFee)?.toFixed(2)}</TotalTextSm>
              </TotalItem>
            )}
            <TotalItem>
              <TotalTextLg>Total (Including GST)</TotalTextLg>
              <TotalTextLg>${cartTotal?.toFixed(2)}</TotalTextLg>
            </TotalItem>
          </TotalContainer>
          <Box
            sx={{
              padding: "0 16px 32px",
              borderBottom: `4px solid ${backgroundTertiary}`,
              "@media screen and (max-width: 768px)": {
                padding: "0 16px 24px",
              },
            }}
          >
            <OrderNoteBottomSheet />
          </Box>
          <Box
            sx={{
              margin: "32px 16px",
              "@media screen and (max-width: 768px)": {
                margin: "24px 16px 0 16px",
              },
            }}
          >
            <DetailsTitle>Your Details</DetailsTitle>
            <OrderCustomerDetailsBottomSheet ref={childRef} />
          </Box>
        </CheckoutContainer>

        <BottomContainer
          headerModalBackground={headerModalBackground}
          backgroundTertiary={backgroundTertiary}
        >
          <PaymentButton
            buttonType={BUTTON_TYPE_CLASSES.cart}
            onClick={async () => {
              if (
                (tableNumber === null || tableNumber === "") &&
                getOrderType === "dinein"
              ) {
                setOrderTypeError(true);
                if (PickupDetailsRef.current) {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              } else {
                await triggerSubmit();
              }
            }}
            isLoading={isLoading}
            style={{ flex: 1, maxWidth: "494px" }}
            text="Continue to Payment"
          />
        </BottomContainer>

        {showOrderTypeBottomSheet && (
          <OrderTypeBottomSheet
            isOpen={showOrderTypeBottomSheet}
            toggleBottomSheet={() => setShowOrderTypeBottomSheet(false)}
            onlineOrdersConfig={onlineOrdersConfig}
            setOrderTypeError={setOrderTypeError}
            tableNumber={tableNumber}
            orderType={getOrderType}
          />
        )}
      </Box>
    );
  }
};

export default Checkout;
